import { useMutation, useQuery } from '@apollo/client'
import { localStore, UserEventType } from '@flock/utils'
import React, { useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { MultiStepper, StepConfig, useTracking } from '@flock/shared-ui'
import {
  LandingGetHomeDetailsDocument,
  Core_TransactionType,
  LandingSearchLeadsByAddressDocument,
  LandingGetLeadDocument,
  LandingCreateLeadDocument,
  LandingUpdateLeadDocument,
  LandingDeleteLeadDocument,
  LandingPostSlackMessageDocument,
  LandingCompletedLeadSideEffectsDocument,
  Core_Lead,
  LandingSubmitEngRangeValuationDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  ONBOARDING_PATH,
  HOMEPAGE_PATH,
} from '@flock/shared-ui/src/routeConstants'
import { DST_LANDING_PAGE_SOURCE } from '../../constants'
import { isValidMarket } from '../utils'
import { isExistingAddressWithCompletedNonDstLead } from './webflowOnboardingInputsConfigs'
import {
  SearchLeadsByAddressLead,
  WebflowData,
  WebflowFunctions,
} from './webflowTypes'

import WebflowHeader, { WebflowHeaderVariant } from './WebflowHeader'
import WebflowHeaderLayout from './WebflowHeaderLayout'
import { InjectedWebflowStepProps, WebflowStepProps } from './WebflowStep'

type WebflowFormProps = {
  stepConfigs: StepConfig<
    WebflowData,
    WebflowFunctions,
    WebflowStepProps<any>,
    InjectedWebflowStepProps
  >[]
  isDirect?: boolean
}

const WebflowForm = (props: WebflowFormProps) => {
  const { track, trackPage } = useTracking()
  const { stepConfigs, isDirect } = props
  const [initialized, setInitialized] = useState(false)

  const [initialStep, setInitialStep] = useState<string>('initialContactInfo')
  const [loadingInitialData, setLoadingInitialData] = useState(true)
  const [step, setStep] = useState<number>(0)
  const [flowData, setWebflowData] = useState<Partial<WebflowData>>({})

  // Functions used for side effects
  const { refetch: getLead } = useQuery(LandingGetLeadDocument, {
    skip: true,
  })
  const { refetch: getHomeDetails } = useQuery(LandingGetHomeDetailsDocument, {
    // We skip the initial fetch since we want to call this endpoint on demand
    skip: true,
  })
  const { refetch: searchLeadsByAddress } = useQuery(
    LandingSearchLeadsByAddressDocument,
    {
      skip: true,
    }
  )
  const [createLead] = useMutation(LandingCreateLeadDocument)
  const [updateLead] = useMutation(LandingUpdateLeadDocument)
  const [deleteLead] = useMutation(LandingDeleteLeadDocument)
  const [submitEngRangeValuation] = useMutation(
    LandingSubmitEngRangeValuationDocument
  )
  const [postSlackMessage] = useMutation(LandingPostSlackMessageDocument)
  const [completedLeadSideEffects] = useMutation(
    LandingCompletedLeadSideEffectsDocument
  )

  const trackOnboardingCompletion = () => {
    track('complete', {
      type: 'button',
      leadConversion: 'finished',
      actionType: UserEventType.BUTTON_CLICK,
    })
  }

  const functions: WebflowFunctions = {
    getLead,
    getHomeDetails,
    createLead,
    updateLead,
    submitEngRangeValuation,
    deleteLead,
    postSlackMessage,
    completedLeadSideEffects,
    searchLeadsByAddress,
    trackOnboardingCompletion,
  }

  const initialize = useCallback(async () => {
    // Extract query parameters and parameters stored in local storage
    const { search } = window.location
    const params = new URLSearchParams(search)
    const paramLeadUuid = params.get('leadUuid')
    const queryParams = localStore?.getItem('queryParams')
    let parsedQueryParams: any = {}
    if (queryParams) {
      parsedQueryParams = JSON.parse(queryParams)
    }

    // If true, we'll start them from the contact info page
    const utmSource =
      params.get('utmSource') ||
      params.get('utm_source') ||
      parsedQueryParams?.utm_source ||
      ''
    const utmCampaign =
      params.get('utmCampaign') ||
      params.get('utm_campaign') ||
      parsedQueryParams?.utm_campaign ||
      ''
    const utmMedium =
      params.get('utmMedium') ||
      params.get('utm_medium') ||
      parsedQueryParams?.utm_medium ||
      ''
    const salesAssignee = params.get('salesAssignee') || ''
    const source = 'onboarding'
    const brokerUuid =
      params.get('brokerUuid') ||
      localStore?.getItem('brokerReferrerUuid') ||
      parsedQueryParams?.brokerUuid ||
      ''
    const fullName = params.get('fullName') || parsedQueryParams?.fullName || ''
    const customerId =
      params.get('customerId') || parsedQueryParams?.customerId || ''

    let phoneNumber =
      params.get('phoneNumber') || parsedQueryParams?.phoneNumber || ''
    // preformat since autofilling in phoneinput doesn't trigger autoformat for validation check
    if (phoneNumber.length === 10) {
      phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`
    }
    const email = params.get('email') || parsedQueryParams?.email || ''

    let newData: Partial<WebflowData> = {
      salesAssignee,
      brokerUuid,
      utmSource,
      utmCampaign,
      utmMedium,
      customerId,
      source,
      contactInfo: {
        fullName,
        email,
        phoneNumber,
      },
      ...flowData,
    }

    let updatedInitialStep = 'address'

    if (utmSource === '0215estatewebinarform') {
      updatedInitialStep = 'webinarInitialStep'
    }
    if (utmSource === '0326taxplanwebinarform') {
      updatedInitialStep = 'taxPlanInitialStep'
    }

    if (paramLeadUuid) {
      newData.leadUuid = paramLeadUuid
      // Get the lead and set the flow data
      let getLeadResult
      try {
        getLeadResult = await getLead({
          input: { leadUuid: paramLeadUuid },
        })
      } catch (error) {
        // handle edge case where duplicate address verification goes to old offer page
        // but then user hits back button after viewing the offer page
        const url = window.location.href
        if (url.includes('duplicateAddressVerification')) {
          window.location.href = ONBOARDING_PATH
        } else {
          throw error
        }
      }

      if (getLeadResult) {
        const { lead } = getLeadResult.data.lead!
        const { source: leadSource } = lead as Core_Lead

        let address: any = {}
        if (lead?.address) {
          const {
            street,
            formattedStreet,
            formattedAddress,
            streetNumber,
            unit,
            city,
            state,
            zipcode,
            latitude,
            longitude,
          } = lead.address

          const { addressUuid } = lead

          address = {
            address: {
              street,
              streetAddress: formattedStreet,
              streetNumber,
              unit,
              city,
              state,
              zipcode,
              lat: latitude,
              lng: longitude,
              addressUuid,
              formattedAddress,
            },
          }
        }
        newData = {
          ...newData,
          address,
          source: leadSource as string,
        }

        if (
          newData.address &&
          newData.address.address &&
          !isValidMarket(newData.address.address.state)
        ) {
          newData.dstLead = true
          newData.transactionType =
            Core_TransactionType.TransactionType_1031Lead
        }

        // If this is an onboarding lead but there exists another lead with the
        // same address, we set the initial step to the duplicate verification
        if (
          newData.address?.address?.addressUuid &&
          leadSource !== DST_LANDING_PAGE_SOURCE
        ) {
          // If it's not a DST lead, check if an existing completed lead exists.
          // If it does, go to the duplicate address verification step
          const sameAddressLeadsResponse = await searchLeadsByAddress({
            input: { addressUuid: newData.address.address.addressUuid },
          })

          if (
            isExistingAddressWithCompletedNonDstLead(
              (sameAddressLeadsResponse?.data?.searchLeadsByAddress
                ?.leads as SearchLeadsByAddressLead[]) || []
            )
          ) {
            updatedInitialStep = 'duplicateAddressVerification'
          } else {
            updatedInitialStep = 'initialContactInfo'
          }
        }

        // If someone lands in the onboarding flow with a completed lead, we'll redirect to the page
        // they entered the onboarding flow from.
        if (
          leadSource !== DST_LANDING_PAGE_SOURCE &&
          lead?.slackThreadUrl &&
          utmSource !== 'fb' &&
          utmSource !== '721_exchange_assessment'
        ) {
          window.location.href = HOMEPAGE_PATH
          return
        }
      }
      setWebflowData(newData)
    } else {
      setWebflowData(newData)
    }

    setInitialStep(updatedInitialStep)
    setLoadingInitialData(false)
  }, [flowData, getLead, searchLeadsByAddress])

  const onUpdated = (newStep: number) => {
    setStep(newStep)
  }

  useEffect(() => {
    if (!initialized) {
      initialize()
      trackPage('enteredOnboarding', {
        category: 'onboarding',
      })
      setInitialized(true)
    }
  }, [initialize, initialized, trackPage])

  let headerVariant = 'addressFirst'
  if (['initialContactInfo', 'hasProceeds'].includes(initialStep)) {
    headerVariant = 'contactFirst'
  }

  return (
    <>
      <WebflowHeaderLayout>
        {!loadingInitialData &&
          !isDirect &&
          stepConfigs[step].stepName !== 'webinarInitialStep' &&
          stepConfigs[step].stepName !== 'taxPlanInitialStep' && (
            <WebflowHeader
              variant={headerVariant as WebflowHeaderVariant}
              currentProgress={stepConfigs[step]!.componentProps!.progress}
            />
          )}
      </WebflowHeaderLayout>
      <Box height="100%">
        {!loadingInitialData ? (
          <Box height="100%">
            <MultiStepper
              initialStep={initialStep}
              initialData={flowData}
              onUpdated={onUpdated}
              stepConfigs={stepConfigs}
              functions={functions}
              useStepComponentLoading
              useStepComponentError
              useHashRouting
            />
          </Box>
        ) : (
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: 'trustBlue.main',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  )
}

export default WebflowForm

WebflowForm.defaultProps = {
  isDirect: false,
}
